import React from "react";
import logo from "../../public/assets/logo.png";

const Navbar = () => {
    return (
        <div className="flex items-center justify-between w-screen h-16 fixed top-0 border-b border-gray-100 px-24">
            <img src={logo} alt="Xoleyo" onClick={() => window.location.href = "/"} className="h-12 w-auto cursor-pointer"/>
            <button className="bg-primary rounded-full text-white font-bold px-4 py-2 hover:bg-primary/80 ">Contact Us</button>
        </div>
  );
};

const IntroPage = () => {
  return (
    <div className="flex flex-col items-center justify-center w-screen">
        <Navbar />
        <div className="flex flex-col items-center justify-center w-screen h-screen">
            <h1 className="text-4xl font-bold font-roboto">Build believable proxies of human behavior for your business</h1>
        </div>

        <footer className="w-full flex flex-col items-start justify-start px-60 pt-10 bg-white border-t border-gray-100">
            <div className="flex pb-20 items-center justify-between w-full">
                <img src={logo} alt="Xoleyo" className="h-12 w-auto"/>
                <div className="flex flex-col items-start justify-start pr-20 gap-2">
                    <span className="text-lg">Contact</span>
                    <span className="text-gray-400/50 text-sm">rayliu@xoleyo.com</span>
                    <span className="text-gray-400/50 text-sm">+1 (703) 928-9950</span>
                </div>
            </div>
            <hr className="w-full border-gray-100 my-4"/>
            <span className="text-gray-500/50 text-sm">© 2024 Xoleyo. All rights reserved.</span>
        </footer>
    </div>
  )
};

export default IntroPage;